import {Box, Breadcrumbs, Grid, Link, Tooltip, Typography} from "@material-ui/core";
import accounting from "accounting";
import classnames from "classnames";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {FilterOptionChips} from "../../components/FilterOptions";
import {FilterSearch, FilterSelect, FilterSwitch} from "../../components/Filters";
import companyTrades from "../../components/forms/choices/companyTrades.json";
import csiCodesNovo from "../../components/forms/choices/csiCodesNovo.json";
import offices from "../../components/forms/choices/offices.json";
import {StatusIcon} from "../../components/Icons";
import {Pagination, PaginationWithPageSize} from "../../components/Pagination";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import useFilterOptions from "../../hooks/useFilterOptions";
import {useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";
import {HighwireStatusIcon} from "./HighwireComponents";

const HighwireContractors = (props) => {
  const {...rest} = props;
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(500);
  const novoClasses = makeNovoClasses();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Search",
    "Market",
    "Trade",
    "Primary CSI Code",
    "Approved Only",
  ]);
  const filterParams = {
    q: filterOptions.Search.value,
    company_trade_id: filterOptions.Trade.value,
    primary_csi_code_id: filterOptions["Primary CSI Code"].value,
    markets: filterOptions.Market.value,
    approved: filterOptions["Approved Only"].value,
  };

  const {query: highwireContractorQuery} = useSentinelListAPI(
    `/highwire/contractors/?ordering=company__name&page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}&has_sentinel_company=true`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      keepPreviousData: true,
    }
  );
  const highwireContractors = highwireContractorQuery.data.results;

  const getTooltip = (highwireContractor) => {
    let tooltip = highwireContractor?.approval_display;
    if (highwireContractor?.override_reason) {
      tooltip += ` (${highwireContractor?.override_reason_display})`;
    }
    return tooltip;
  };

  return (
    <>
      <Helmet title="Highwire Contractors" />
      <Breadcrumbs>
        <Link href="/v2/dashboard/">
          <Typography color="textSecondary">Dashboard</Typography>
        </Link>
        <Typography color="textPrimary">Highwire Contractors</Typography>
      </Breadcrumbs>
      <Box mt={2} />
      {/* <pre>{JSON.stringify(highwireContractorQuery, null, 2)}</pre> */}
      <PaperPanel>
        <PaperPanel.Header isLoading={highwireContractorQuery.isFetching}>
          <PaperPanel.Header.Title>Highwire Contractors</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => {
                  highwireContractorQuery.refetch();
                  highwireContractorQuery.refetch();
                }}
                isFetching={highwireContractorQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FilterSelect
                label="Market"
                name="market"
                options={offices}
                allowNull
                // value={filterOptions["Has Sentinel Company"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Market", value, label);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FilterSelect
                label="Trade"
                name="trade"
                options={companyTrades}
                allowNull
                // value={filterOptions["Has Sentinel Company"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Trade", value, label);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FilterSelect
                label="Primary CSI Code"
                name="primary_csi_code"
                options={csiCodesNovo}
                allowNull
                // value={filterOptions["Has Sentinel Company"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Primary CSI Code", value, label);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FilterSwitch
                label="Approved Only"
                name="approved"
                checked={filterOptions["Approved Only"].value}
                onChange={(value) => {
                  setPage(1);
                  if (value) {
                    setFilterOption("Approved Only", value, value);
                  } else {
                    clearFilterOption("Approved Only");
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Pagination count={highwireContractorQuery.data.total_pages} page={page} setPage={setPage} />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            columns={[
              {
                Header: "Sentinel Company",
                accessor: "sentinel_company.name",
                sortType: "basic",
                // disableSortBy: true,
                Cell: ({value, row}) => (
                  <>
                    <Link underline="always" target="_blank" href={row.original.sentinel_company?.url}>
                      {value}
                    </Link>
                  </>
                ),
              },
              {
                Header: "Safety Score",
                accessor: "safety_score",
                Cell: ({value, row}) => {
                  if (!value) return null;
                  return (
                    <>
                      <Tooltip title="Out of 100">
                        <span>{accounting.format(value, 2)}</span>
                      </Tooltip>
                    </>
                  );
                },
              },
              {
                accessor: "is_approved",
                Header: "Approval",
                Cell: ({row, value}) => <HighwireStatusIcon highwireContractor={row.original} />,
                sortType: "basic",
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "sentinel_company.name",
                  desc: false,
                },
              ],
            }}
            data={highwireContractors}
          />
          {/* <pre>{JSON.stringify(csiCodesNovo, null, 2)}</pre> */}
        </PaperPanel.Body>
        {(highwireContractorQuery.data.total_pages > 1 || pageSize > 100) && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={highwireContractorQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              pageSizes={[10, 100, 250, 500, 1000]}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>
    </>
  );
};

export default HighwireContractors;

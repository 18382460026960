import {faExclamationCircle} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Tooltip} from "@material-ui/core";
import accounting from "accounting";
import moment from "moment";
import {colorError, colorWarning} from "../../theme/colors";

const getExpirationDateColor = (date) => {
  const dateToCheck = moment(date);
  const today = moment();
  const warningDate = moment().add(30, "days");
  if (dateToCheck.isBefore(today)) {
    return colorError;
  }
  if (dateToCheck.isBefore(warningDate)) {
    return colorWarning;
  }
};

const COIExpirationDateItem = (props) => {
  const {date, ...rest} = props;
  let tooltip: string | number = moment(date).diff(moment(), "days");
  if (tooltip < 0) {
    tooltip = `Expired ${accounting.formatNumber(Math.abs(tooltip))} days ago`;
  } else {
    tooltip = `Expires in ${accounting.formatNumber(tooltip)} days`;
  }

  return (
    <Tooltip title={tooltip}>
      <span style={{color: getExpirationDateColor(date)}} {...rest}>
        {date}
      </span>
    </Tooltip>
  );
};

export const COIExpirationDateTableCell = ({value, row}) => {
  const expirationDates = row.original.expiration_dates_json;
  return (
    // <Box display="flex">
    //   {expirationDates.map((date) => (
    //     <Box mr={1} key={date}>
    //       <COIExpirationDateItem date={date} />
    //     </Box>
    //   ))}
    // </Box>
    <>
      {expirationDates.map((date) => (
        <Box key={date}>
          <COIExpirationDateItem date={date} />
        </Box>
      ))}
    </>
  );
};

type COIInsuranceRequirementsIconProps = {
  coi: {
    insurance_in_compliance: boolean;
    insurance_compliance_issues: string[];
  };
  rest?: React.HTMLAttributes<HTMLElement>;
};

export const COIInsuranceRequirementsIcon: React.FC<COIInsuranceRequirementsIconProps> = ({coi, ...rest}) => {
  if (coi.insurance_in_compliance) {
    return null;
  }
  return (
    <>
      <Tooltip
        title={
          <>
            {coi.insurance_compliance_issues.map((issue) => (
              <div key={issue}>{issue}</div>
            ))}
          </>
        }
      >
        <FontAwesomeIcon icon={faExclamationCircle} color={colorError} fixedWidth {...rest} />
      </Tooltip>
    </>
  );
};

import {faEdit, faExternalLink, faListCheck} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Link, Tooltip, Typography} from "@material-ui/core";
import accounting from "accounting";
import {toTitleCase} from "../../../js/utils/string";
import {LabelValue} from "../../components/LabelValue";
import {StatusLabel} from "../../components/Status";
import usePermissions from "../../hooks/usePermissions";
import {HighwireStatusIcon} from "./HighwireComponents";

const HighwireFlag = (props) => {
  const {flag} = props;
  const severityDisplay = toTitleCase(flag.flagSeverity);

  return (
    <>
      <Box display="flex-inline">
        <StatusLabel status={severityDisplay} /> {flag.flagType}: {flag.flagText}
      </Box>
      {/* <pre>{JSON.stringify(flag, null, 2)}</pre> */}
    </>
  );
};

const HighwireInfo = (props) => {
  const {highwireContractor, onOverride} = props;
  const permissions = usePermissions();

  const flags = highwireContractor?.data?.assessment?.flags || [];
  const showFlags = permissions.can_manage_safety_reviews && flags.length > 0;

  return (
    <>
      {/* <Link underline="always" target="_blank" href={highwireContractor?.highwire_url}>
        <FontAwesomeIcon icon={faExternalLink} />
      </Link> */}
      <HighwireStatusIcon highwireContractor={highwireContractor} />
      <LabelValue label=" Highwire" component="span">
        {highwireContractor.safety_completion_percentage === 100 ? (
          <Tooltip title="Out of 100">
            <span>{accounting.format(highwireContractor?.safety_score, 2)}</span>
          </Tooltip>
        ) : (
          <Tooltip title="Safety Score Not Available: Insufficient Data">
            <span>N/A</span>
          </Tooltip>
        )}
      </LabelValue>
      <Box ml={1} component="span">
        {permissions.can_override_highwire_approval && (
          <Tooltip title="Override Highwire Approval">
            <Box component="span" ml={0.5} style={{cursor: "pointer"}}>
              <FontAwesomeIcon icon={faEdit} onClick={onOverride} />
            </Box>
          </Tooltip>
        )}
      </Box>

      {/* {showFlags && (
        <>
          <Box>
            <strong>Flags</strong>
          </Box>
          {flags.map((flag, i) => (
            <HighwireFlag key={i} flag={flag} />
          ))}
        </>
      )} */}
    </>
  );
};

export default HighwireInfo;

import {AppBar, Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tab} from "@material-ui/core";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import accounting from "accounting";
import {Switches as FinalSwitches} from "mui-rff";
import {Form as FinalForm} from "react-final-form";
import {useQueryState} from "use-location-state";
import BeforeUnload from "../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../api";
import companyDiversity from "../../components/forms/choices/companyDiversity.json";
import companyRoles from "../../components/forms/choices/companyRoles.json";
import companySectors from "../../components/forms/choices/companySectors.json";
import companyTrades from "../../components/forms/choices/companyTrades.json";
import csiCodesNovo from "../../components/forms/choices/csiCodesNovo.json";
import ethnicity from "../../components/forms/choices/ethnicity.json";
import highwireOverrideReasons from "../../components/forms/choices/highwireOverrideReasons.json";
import insurancePolicyTypes from "../../components/forms/choices/insurancePolicyTypes.json";
import offices from "../../components/forms/choices/offices.json";
import usStates from "../../components/forms/choices/usStates.json";
import {
  AutoselectMui,
  CompanyContactAutoselectMui,
  CSVTagsMui,
  DatePickerMui,
  GoogleMapsAddressAutocompleteMui,
  MaskedInputMui,
  SimpleAutoselectMui,
  SimpleSelect,
  TextFieldMui,
  TimberlineVendorAutoselectMui,
} from "../../components/forms/Fields";
import FormActions from "../../components/forms/FormActions";
import InnerFooter from "../../components/InnerFooter";
import PaperPanel from "../../components/PaperPanel";
import usePermissions from "../../hooks/usePermissions";
import {makeNovoClasses} from "../../theme";
import {simpleMemoize} from "../../utils/simpleMemoize";
import {validateURL} from "../../utils/validators";

const validateLogoURL = simpleMemoize(async (value, values, field) => {
  if (!value) {
    return;
  }
  if (!validateURL(value)) {
    return "The URL is invalid.";
  }
  const response = await axiosAPI.post("/reports2/check-logo-url-is-valid/", {logo_url: value});
  if (response.data === "invalid") {
    return "The image is invalid.";
  }
});

export const CompanyCreateForm = (props: {
  onSubmit: (values: any) => void;
  // All other props
  [rest: string]: any;
}) => {
  const permissions = usePermissions();

  const {onSubmit, ...rest} = props;
  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {({handleSubmit, form, submitting, pristine, values}) => (
        <>
          <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
            <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
              <PaperPanel.Body>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextFieldMui name="name" label="Name" required autoFocus />
                  </Grid>
                  <Grid item xs={12}>
                    <CSVTagsMui
                      name="nicknames"
                      label="Nicknames"
                      helperText={`These names will be included when searching`}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextFieldMui
                      name="email_domain"
                      label="Email Domain"
                      helperText={
                        <>
                          Example: novoconstruction.com <i>(used to link contacts to companies)</i>
                        </>
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SimpleAutoselectMui name="company_role_id" label="Company Role" options={companyRoles} required />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <SimpleAutoselectMui name="primary_csi_code" label="Primary CSI Code" options={csiCodesNovo} />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <FinalSwitches name="is_union" data={{label: "Is Union", value: true}} />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <FinalSwitches
                      name="is_subcontract_blocklisted"
                      data={{label: "Subcontract Blocklisted", value: true}}
                      disabled={!permissions.is_executive}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AutoselectMui
                      name="markets"
                      label="Markets"
                      valueProp="id"
                      labelProp="description"
                      multiple
                      options={offices.map((option) => {
                        return {
                          id: option.value,
                          description: option.label,
                        };
                      })}
                    />
                  </Grid>
                </Grid>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              </PaperPanel.Body>
              <InnerFooter>
                <FormActions.CreateButton disabled={submitting || pristine} />
              </InnerFooter>
            </FinalFormKeyboardShortcuts>
          </form>
        </>
      )}
    </FinalForm>
  );
};

export const CompanyUpdateForm = (props: {
  onSubmit: (values: any) => void;
  // All other props
  [rest: string]: any;
}) => {
  const [selectedTab, setSelectedTab] = useQueryState("tab", "details");
  const permissions = usePermissions();
  const novoClasses = makeNovoClasses();
  const isSubcontractor = Boolean(props.initialValues?.company_role_id === "subcontractor");

  const {onSubmit, ...rest} = props;
  return (
    <TabContext value={selectedTab}>
      <PaperPanel>
        <PaperPanel.Body>
          <Box mb={2}>
            <AppBar
              position="static"
              color="default"
              // elevation={0}
            >
              <TabList
                value={selectedTab}
                onChange={(event, newValue) => {
                  setSelectedTab(newValue);
                  // navigate(newValue);
                }}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Details" value="details" className={novoClasses.smallTab} />

                <Tab
                  label="Diversity"
                  value="diversity"
                  className={novoClasses.smallTab}
                  style={{display: isSubcontractor ? "" : "none"}}
                />
              </TabList>
            </AppBar>
          </Box>
        </PaperPanel.Body>

        <PaperPanel.Body>
          <FinalForm onSubmit={onSubmit} {...rest}>
            {({handleSubmit, form, submitting, pristine, values}) => (
              <>
                <BeforeUnload block={!pristine} />
                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <PaperPanel.Body>
                      <TabPanel value="details">
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextFieldMui name="name" label="Name" required autoFocus />
                          </Grid>
                          <Grid item xs={12}>
                            <CSVTagsMui
                              name="nicknames"
                              label="Nicknames"
                              helperText={`These names will be included when searching`}
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <TextFieldMui
                              name="email_domain"
                              label="Email Domain"
                              helperText={
                                <>
                                  Example: novoconstruction.com <i>(used to link contacts to companies)</i>
                                </>
                              }
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <TextFieldMui
                              name="logo_url"
                              label="Logo URL"
                              fieldProps={{
                                validate: validateLogoURL,
                              }}
                            />
                            {values.logo_url && (
                              <Box mt={1}>
                                <img src={values.logo_url} style={{maxHeight: 50, maxWidth: 400}} />
                              </Box>
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            <SimpleAutoselectMui
                              name="company_role"
                              label="Company Role"
                              options={companyRoles}
                              required
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <AutoselectMui
                              name="company_trades"
                              label="Company Trades"
                              valueProp="id"
                              labelProp="description"
                              multiple={true}
                              disableClearable={false}
                              options={companyTrades.map((option) => {
                                return {
                                  id: option.value,
                                  description: option.label,
                                };
                              })}
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <TextFieldMui name="tax_id" label="Tax Id" />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <SimpleAutoselectMui
                              name="primary_csi_code"
                              label="Primary CSI Code"
                              options={csiCodesNovo}
                            />
                          </Grid>
                          <Grid item sm={2} xs={12}>
                            <FinalSwitches name="is_union" data={{label: "Is Union", value: true}} />
                          </Grid>
                          <Grid item sm={2} xs={12}>
                            <FinalSwitches
                              name="is_subcontract_blocklisted"
                              data={{label: "Subcontract Blocklisted", value: true}}
                              disabled={!permissions.is_executive}
                            />
                          </Grid>
                          <Grid item sm={2} xs={12}>
                            <TextFieldMui name="emr" label="EMR" type="number" helperText="Experience modifier rate" />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <SimpleAutoselectMui name="sector" label="Sector" options={companySectors} />
                          </Grid>
                          <Grid item xs={12}>
                            <CompanyContactAutoselectMui
                              companyId={values.id}
                              name="signator"
                              label="Signator"
                              disabled={!permissions.can_update_company_signator}
                              helperText="This contact will be the signer for all documents to this company"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <AutoselectMui
                              name="markets"
                              label="Markets"
                              valueProp="id"
                              labelProp="description"
                              multiple
                              options={offices.map((option) => {
                                return {
                                  id: option.value,
                                  description: option.label,
                                };
                              })}
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <FinalSwitches name="is_active" data={{label: "Is Active", value: true}} />
                          </Grid>
                        </Grid>
                      </TabPanel>

                      <TabPanel value="diversity">
                        <Grid container spacing={2}>
                          <Grid item sm={6} xs={12}>
                            <SimpleSelect
                              name="owner_ethnicity"
                              label="Owner Ethnicity"
                              options={ethnicity}
                              allowNull
                            />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <TextFieldMui name="certification_agency" label="Certification Agency" />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <TextFieldMui name="certification_number" label="Certification Number" />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <DatePickerMui name="certification_expiration" label="Certification Expiration" />
                          </Grid>
                          <Grid item xs={12}>
                            <AutoselectMui
                              name="company_diversity"
                              label="Company Diversity"
                              valueProp="id"
                              labelProp="description"
                              multiple={true}
                              disableClearable={false}
                              options={companyDiversity.map((option) => {
                                return {
                                  id: option.value,
                                  description: option.label,
                                };
                              })}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextFieldMui
                              name="company_diversity_notes"
                              label="Diversity Notes"
                              multiline
                              minRows={3}
                            />
                          </Grid>
                        </Grid>
                      </TabPanel>
                    </PaperPanel.Body>
                    <InnerFooter>
                      <FormActions.SaveButton disabled={submitting || pristine} />
                    </InnerFooter>
                    {/* <FormActions>
                        <FormActions.Left>
                          <FormActions.SaveButton disabled={submitting || pristine} />
                        </FormActions.Left>
                      </FormActions> */}
                    {/* <pre>{JSON.stringify(permissions, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  </FinalFormKeyboardShortcuts>
                </form>
              </>
            )}
          </FinalForm>
        </PaperPanel.Body>
      </PaperPanel>
    </TabContext>
  );
};

export const CompanyLocationDialogForm = (props: {
  onSubmit: (values: any) => void;
  onDelete?: () => void;
  isOpen: boolean;
  isNew?: boolean;
  fieldSize?: string;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, onDelete, isNew = false, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">{isNew ? "Create Location" : "Update Location"}</DialogTitle>
              <DialogContent>
                {/* <DialogContentText>DialogContentText</DialogContentText> */}
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextFieldMui name="description" label="Description" autoFocus />
                    </Grid>
                    <Grid item xs={12}>
                      <GoogleMapsAddressAutocompleteMui
                        name="address1"
                        label="Address 1"
                        onPlaceSelected={(place) => {
                          form.change("address1", place.address1);
                          form.change("address2", place.address2);
                          form.change("city", place.locality_long_name);
                          form.change("state", place.administrative_area_level_1_short_name);
                          form.change("postal_code", place.postal_code);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldMui name="address2" label="Address 2" />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldMui name="address3" label="Address 3" />
                    </Grid>
                    <Grid item xs={6}>
                      <TextFieldMui name="city" label="City" />
                    </Grid>
                    <Grid item xs={3}>
                      <SimpleAutoselectMui name="state" label="State" options={usStates} />
                    </Grid>
                    <Grid item xs={3}>
                      <TextFieldMui name="postal_code" label="Postal code" />
                    </Grid>
                    <Grid item xs={4}>
                      <MaskedInputMui name="phone" label="Phone" mask="(999) 999-9999" />
                    </Grid>
                    <Grid item xs={4}>
                      <MaskedInputMui name="fax" label="Fax" mask="(999) 999-9999" />
                    </Grid>
                    <Grid item xs={4}>
                      <MaskedInputMui name="toll_free" label="Toll Free" mask="(999) 999-9999" />
                    </Grid>
                    <Grid item xs={6}>
                      <FinalSwitches name="is_primary" data={{label: "Primary Location", value: true}} />
                    </Grid>
                  </Grid>
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} />
                {!isNew && <FormActions.DeleteButton onClick={() => onDelete()} />}
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const CompanyInsuranceDialogForm = (props: {
  onSubmit: (values: any) => void;
  onDelete?: () => void;
  isOpen: boolean;
  isNew?: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, onDelete, isOpen, handleClose, isNew = false, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">
                {isNew ? "Create Insurance Policy" : "Update Insurance Policy"}
              </DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <PaperPanel.Body>
                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12}>
                        <SimpleAutoselectMui
                          name="type"
                          label="Type"
                          options={insurancePolicyTypes}
                          autoFocus={isNew}
                          required
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextFieldMui name="policy_number" label="Policy Number" autoFocus={!isNew} required />
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldMui name="description" label="Description" />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <DatePickerMui name="start_date" label="Start Date" required />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <DatePickerMui name="ex_date" label="Expiration Date" required />
                      </Grid>
                    </Grid>
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  </PaperPanel.Body>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                {!isNew && <FormActions.DeleteButton onClick={() => onDelete()} />}
                <FormActions.SaveButton disabled={submitting || pristine} label={isNew ? "Create" : "Save"} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const TimberlineVendorLinkForm = (props: {
  onSubmit: (values: any) => void;
  // All other props
  [rest: string]: any;
}) => {
  const {onSubmit, ...rest} = props;
  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {({handleSubmit, form, submitting, pristine, values}) => (
        <>
          <BeforeUnload block={!pristine} />
          <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
            <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TimberlineVendorAutoselectMui
                    label="Vendor"
                    name="timberline_vendor"
                    // required
                    baseURL="timberline/vendors/"
                    qsParams={{
                      page_size: 10,
                    }}
                  />
                </Grid>
              </Grid>
              <Box mb={2} />
              <FormActions.SaveButton disabled={submitting || pristine} />
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            </FinalFormKeyboardShortcuts>
          </form>
        </>
      )}
    </FinalForm>
  );
};

export const HighwireApprovalOverrideDialogForm = (props: {
  onSubmit: (values: any) => void;
  onDelete?: () => void;
  isOpen: boolean;
  isNew?: boolean;
  handleClose: () => void;
  contractor: any;
  [rest: string]: any;
}) => {
  const {onSubmit, onDelete, isOpen, handleClose, contractor, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">Override Highwire Approval</DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <PaperPanel.Body>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {contractor.sentinel_company.name} has a Highwire Safety Score of{" "}
                        <strong>{accounting.format(contractor.safety_score, 2)}</strong> and is currently{" "}
                        <strong>{contractor.is_approved ? "Approved" : "Not Approved"}</strong>. Overrides to this
                        approval will last for the remainder of the current fiscal year.
                      </Grid>
                      <Grid item xs={12}>
                        <SimpleSelect
                          name="override_reason"
                          label="Reason"
                          options={[...highwireOverrideReasons, {value: "remove", label: "Remove Override"}]}
                          helperText="Please select a reason for overriding this approval"
                        />
                      </Grid>
                    </Grid>
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  </PaperPanel.Body>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} label={"Save"} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

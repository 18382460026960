import {
  faBuilding,
  faCheck,
  faCircleNotch,
  faFax,
  faFileExcel,
  faLink,
  faMapMarker,
  faPhone,
  faUmbrella,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, Breadcrumbs, Divider, Grid, Link, Tab, Tabs, Tooltip, Typography} from "@material-ui/core";
import {green, grey, red} from "@material-ui/core/colors";
import EditIcon from "@material-ui/icons/Edit";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import RoomIcon from "@material-ui/icons/Room";
import {TabContext, TabPanel} from "@material-ui/lab";
import accounting from "accounting";
import classnames from "classnames";
import {uniq} from "lodash";
import moment from "moment";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import Moment from "react-moment";
import {PDFObject} from "react-pdfobject";
import {useParams} from "react-router";
import {useQueryState} from "use-location-state";
import {VictoryAxis, VictoryChart, VictoryContainer, VictoryLine} from "victory";
import {Currency} from "../../components/Accounting";
import {Address} from "../../components/Address";
import CreatedByModifiedBy from "../../components/CreatedByModifiedBy";
import {ConfirmationDialog} from "../../components/Dialogs";
import {FilterOptionChips} from "../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../components/Filters";
import insuranceAcordForms from "../../components/forms/choices/insuranceAcordForms.json";
import BlockUI from "../../components/GlobalLoaders";
import {ProjectStatusIcon} from "../../components/Icons";
import {DaysUntilExpiration} from "../../components/Insurance";
import {LabelBoolean, LabelValue} from "../../components/LabelValue";
import {MuiNavLink, PortalLink} from "../../components/Links";
import {PageHeader} from "../../components/PageHeader";
import {Pagination, PaginationWithPageSize} from "../../components/Pagination";
import {PaperItem} from "../../components/PaperItem";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import useBlockUI from "../../hooks/useBlockUI";
import useFilterOptions from "../../hooks/useFilterOptions";
import usePermissions from "../../hooks/usePermissions";
import {useFetchCurrentPage, useSentinelDetailAPI, useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";
import {colorMutedText, colorSuccess} from "../../theme/colors";
import {saveExcel} from "../../utils/excel";
import {truncateString} from "../../utils/string";
import {ContactDialogForm} from "../contacts/ContactForms";
import {COIExpirationDateTableCell} from "../Insurance/COIComponents";
import {COIUpdateDialogForm} from "../Insurance/COIForms";
import {
  CompanyInsuranceDialogForm,
  CompanyLocationDialogForm,
  HighwireApprovalOverrideDialogForm,
  TimberlineVendorLinkForm,
} from "./CompanyForms";
import HighwireInfo from "./HighwireInfo";
import {axiosAPI} from "../../api";

const CompanyDetail = (props) => {
  const {user} = props;
  const [projectsPage, setProjectsPage] = React.useState(1);
  const [projectsPageSize, setProjectsPageSize] = React.useState(250);
  const [projectsSearch, setProjectsSearch] = React.useState("");
  const [contactsPage, setContactsPage] = React.useState(1);
  const [contactsPageSize, setContactsPageSize] = React.useState(250);
  const [contactsOrdering, setContactsOrdering] = React.useState("");
  const [contactsSearch, setContactsSearch] = React.useState("");
  const [commitmentsPageSize, setCommitmentsPageSize] = React.useState(250);
  const [commitmentsPage, setCommitmentsPage] = React.useState(1);
  const [coiPageSize, setCOIPageSize] = React.useState(250);
  const [coiPage, setCOIPage] = React.useState(1);
  const [showLocationCreateDialog, setShowLocationCreateDialog] = React.useState(false);
  const [showLocationUpdateDialog, setShowLocationUpdateDialog] = React.useState(false);
  const [showContactCreateDialog, setShowContactCreateDialog] = React.useState(false);
  const [showInsuranceCreateDialog, setShowInsuranceCreateDialog] = React.useState(false);
  const [showInsuranceUpdateDialog, setShowInsuranceUpdateDialog] = React.useState(false);
  const [showUpdateCOIDialog, setShowUpdateCOIDialog] = React.useState(false);
  const [deleteLocationConfirmationIsOpen, setDeleteLocationConfirmationIsOpen] = React.useState(false);
  const [deleteInsuranceConfirmationIsOpen, setDeleteInsuranceConfirmationIsOpen] = React.useState(false);
  const [unlinkTimberlineVendorConfirmationIsOpen, setUnlinkTimberlineVendorConfirmationIsOpen] = React.useState(false);
  const [markContactAsPrimaryConfirmationIsOpen, setMarkContactAsPrimaryConfirmationIsOpen] = React.useState(false);
  const [activeLocation, setActiveLocation] = React.useState({} as any);
  const [activeContact, setActiveContact] = React.useState({} as any);
  const [activeInsurancePolicy, setActiveInsurancePolicy] = React.useState({} as any);
  const [activeCOI, setActiveCOI] = React.useState({} as any);
  const [highwireApprovalDialogFormIsOpen, setHighwireApprovalDialogFormIsOpen] = React.useState(false);

  const {companyId} = useParams();
  const [selectedTab, setSelectedTab] = useQueryState("tab", "projects");
  const permissions = usePermissions();
  const blockUI = useBlockUI();
  const novoClasses = makeNovoClasses();

  const projectColumns = React.useMemo(
    () => [
      {
        Header: "Project",
        accessor: (value) => {
          return `${value.status_sort}-${value.display}`;
        },
        disableSortBy: true,
        Cell: ({value, row}) => {
          return (
            <>
              <ProjectStatusIcon project={row.original} showTooltip />{" "}
              <Link href={`${row.original.url}`} underline="always">
                {truncateString(row.original.display, 60)}
              </Link>
            </>
          );
        },
      },
      {
        Header: "Role",
        accessor: (value) => {
          const companyRoles = uniq(value.project_company.map((projectCompany) => projectCompany.company_role_display));
          return companyRoles.join(", ");
        },
        disableSortBy: true,
        Cell: ({value}) => <>{truncateString(value, 50)}</>,
      },
      {
        Header: "Project Manager",
        accessor: "project_manager",
        disableSortBy: true,
      },
      {
        id: "csi_codes",
        Header: "CSI Code",
        accessor: (value) => {
          const csiCodes = uniq(value.project_company.map((projectCompany) => projectCompany.csi_code?.display));
          return csiCodes.join(", ");
        },
        disableSortBy: true,
        Cell: ({value}) => <>{truncateString(value, 50)}</>,
      },
      {
        Header: "Address",
        accessor: "full_address",
        disableSortBy: true,
        Cell: ({value}) => <small>{truncateString(value, 50)}</small>,
      },
    ],
    []
  );

  const contactColumns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "full_name",
        disableSortBy: true,
        Cell: ({value, row}) => {
          return (
            <MuiNavLink href={`/v2/contacts/${row.original.id}/`} underline="always">
              {value}
            </MuiNavLink>
          );
        },
      },
      {
        Header: "Title",
        accessor: "title",
        disableSortBy: true,
      },
      {
        Header: "Email",
        accessor: "email",
        disableSortBy: true,
        Cell: ({value, row}) => {
          return (
            <Link href={`mailto:${value}`} underline="always">
              {value}
            </Link>
          );
        },
      },
      {
        Header: "",
        accessor: "is_primary",
        disableSortBy: true,
        Cell: ({value, row}) => {
          return (
            <Tooltip title="Is Primary">
              <span>
                <FontAwesomeIcon
                  icon={faCheck}
                  color={value ? colorSuccess : colorMutedText}
                  style={{cursor: "pointer"}}
                  onClick={() => {
                    setActiveContact(row.original);
                    setMarkContactAsPrimaryConfirmationIsOpen(true);
                  }}
                />
              </span>
            </Tooltip>
          );
        },
      },
    ],
    []
  );

  const insuranceColumns = React.useMemo(
    () => [
      {
        Header: "Type",
        accessor: "type_display",
      },
      {
        Header: "Policy Number",
        accessor: "policy_number",
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        Cell: ({value}) => (value ? moment(value).format("YYYY-MM-DD") : ""),
      },
      {
        Header: "Ex Date",
        accessor: "ex_date",
        Cell: ({value}) => (value ? moment(value).format("YYYY-MM-DD") : ""),
      },

      {
        Header: "Days Until Ex",
        accessor: "days_until_expiration",
        Cell: ({value, row}) => {
          return <DaysUntilExpiration days={value} />;
        },
      },
    ],
    []
  );
  const coiColumns = React.useMemo(
    () => [
      {
        Header: "Project",
        accessor: "project.display",
        Cell: ({value, row}) => (
          <>
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <Link href={row.original.url} target="_blank">
                  <FontAwesomeIcon icon={faLink} />
                </Link>
              </Box>
              <Box>{value || "No Project Found"}</Box>
            </Box>

            {/* {row.original?.project?.id && (
              <Link underline="always" href={`/v2/projects/${row.original?.project?.id}/insurance/#tab=cois`}>
                {value}
              </Link>
            )} */}
          </>
        ),
      },
      {
        Header: "ACORD Form",
        accessor: "acord_form_display",
      },
      {
        Header: "COI Date",
        accessor: "coi_date",
      },
      {
        Header: "Ex Date(s)",
        accessor: "min_expiration_date",
        Cell: ({value, row}) => <COIExpirationDateTableCell value={value} row={row} />,
      },
      {
        Header: "Processed",
        accessor: "ai_processed",
        Cell: ({value, row}) => <>{value && <Moment calendar withTitle date={value} />}</>,
      },
    ],
    []
  );

  const commitmentColumns = React.useMemo(
    () => [
      {
        id: "project",
        Header: "Project",
        accessor: (value) => {
          return `${value.project.status_sort}-${value.project.display}`;
        },
        style: {whiteSpace: "hidden"},
        Cell: ({value, row}) => (
          <Box whiteSpace="nowrap" overflow="hidden" maxWidth={250} textOverflow="ellipsis">
            <ProjectStatusIcon project={row.original.project} />{" "}
            <Link href={`/v2/projects/${row.original.project.id}/`} underline="always">
              {row.original.project.display}
            </Link>
          </Box>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({value, row}) => {
          return (
            <Link
              href={`${row.original.sentinel_subcontract_url || row.original.sentinel_po_url || row.original.url}`}
              underline="always"
            >
              {value}
            </Link>
          );
        },
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Orig Amount",
        accessor: "amount",
        Cell: ({value, row}) => <Currency number={value} precision={2} />,
      },

      {
        Header: "Approved CO's",
        accessor: "approved_commitment_co_amount",
        Cell: ({value, row}) => <Currency number={value} precision={2} />,
      },
      {
        Header: "Amount",
        accessor: "committed",
        Cell: ({value, row}) => <Currency number={value} precision={2} />,
      },
    ],
    []
  );

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {},
  });
  const {query: companyQuery, update: updateCompany} = useSentinelDetailAPI(`companies/${companyId}/`, {
    initialData: {},
    keepPreviousData: true,
  });
  const {query: projectsQuery} = useSentinelListAPI(
    `companies/${companyId}/projects/?page_size=${projectsPageSize}&page=${projectsPage}&q=${projectsSearch}`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    }
  );
  const {
    query: contactsQuery,
    create: createContact,
    update: updateContact,
  } = useSentinelListAPI(
    `contacts/?company_id=${companyId}&page_size=${contactsPageSize}&page=${contactsPage}&ordering=${contactsOrdering}&q=${contactsSearch}`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    }
  );
  const {
    query: locationsQuery,
    create: createLocation,
    update: updateLocation,
    delete: deleteLocation,
  } = useSentinelListAPI(`companies/${companyId}/locations/?no_deleted=True`, {
    initialData: {
      results: [],
    },
    keepPreviousData: true,
  });

  const company = companyQuery.data;
  const pageData = pageDataQuery.data;
  const projects = projectsQuery.data.results;
  const contacts = contactsQuery.data.results;

  const locations = locationsQuery.data.results;

  const isSubcontractor = company.company_role_id === "subcontractor";
  const currentYear = new Date().getFullYear();
  const showInsurance = ["subcontractor", "general_contractor", "other"].includes(company.company_role_id);

  const {
    query: insuranceQuery,
    create: createInsurance,
    update: updateInsurance,
    delete: deleteInsurance,
  } = useSentinelListAPI(`companies/${companyId}/insurance/`, {
    initialData: {
      results: [],
    },
    enabled: showInsurance,
  });
  const insurance = insuranceQuery.data.results;

  const [coiFilterOptions, setCOIFilterOption, clearCOIFilterOption, clearAllCOIFilterOptions] = useFilterOptions([
    "Search",
    "Latest",
    "Acord Form",
  ]);
  const coiFilterParams = {
    q: coiFilterOptions.Search.value,
    latest: coiFilterOptions.Latest.value,
    acord_form: coiFilterOptions["Acord Form"].value,
  };

  const {
    query: coiQuery,
    update: updateCOI,
    delete: deleteCOI,
    rpc: coiRPC,
  } = useSentinelListAPI(
    `/insurance/cois/?page_size=${coiPageSize}&page=${coiPage}&ordering=-coi_date&insured_fuzzy_company=${companyId}&${qs.stringify(coiFilterParams)}`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      // keepPreviousData: true,
    },
    {
      idProp: "uuid",
    }
  );
  const cois = coiQuery.data.results;

  const {query: timberlineCommitmentsQuery} = useSentinelListAPI(
    `companies/${companyId}/timberline-commitments/?page_size=${commitmentsPageSize}&page=${commitmentsPage}`,
    {
      initialData: {
        results: [],
      },
      enabled: pageData.showTimberlineCommitments || false,
      keepPreviousData: true,
    }
  );
  const timberlineCommitments = timberlineCommitmentsQuery.data.results;

  const {query: highwireContractorQuery} = useSentinelDetailAPI(`companies/${companyId}/highwire-contractor/`, {
    initialData: {},
    keepPreviousData: true,
    enabled: Boolean(company?.highwire_contractor_id),
  });
  const highwireContractor = highwireContractorQuery?.data;

  const {query: overrideQuery} = useSentinelDetailAPI(`highwire/contractors/${highwireContractor.id}/override/`, {
    initialData: {},
    enabled: Boolean(highwireContractor?.id),
  });

  const override = overrideQuery.data;

  if (!companyQuery.isFetchedAfterMount || !pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching company info..." />;
  }

  return (
    <>
      <Helmet title={company.display} />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <Typography color="textPrimary">{company.name}</Typography>
      </Breadcrumbs>
      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faBuilding} /> {company.name}
          </PageHeader.Title>
        </PageHeader.Left>
        {permissions.can_view_company_financials && (
          <PageHeader.Right>
            {isSubcontractor ? (
              <Tooltip title="Timberline Vendor WIP">
                <span>
                  <PageHeader.Right.CurrencySpark
                    title="Work in Progress"
                    number={pageData.vendorInProgressWork}
                    color={green[800]}
                  />
                </span>
              </Tooltip>
            ) : (
              <>
                {pageData.totalProjectsValue !== undefined && (
                  <Tooltip title="Sentinel Current Budget">
                    <span>
                      <PageHeader.Right.CurrencySpark
                        title="Work in Progress"
                        number={pageData.totalProjectsValue}
                        color={green[800]}
                      />
                    </span>
                  </Tooltip>
                )}
                {pageData.totalProjectsReceivables !== undefined && (
                  <Tooltip title="Timberline Receivable Balance">
                    <span>
                      <PageHeader.Right.CurrencySpark
                        title="Total Receivables"
                        number={pageData.totalProjectsReceivables}
                        color={red[800]}
                        border
                      />
                    </span>
                  </Tooltip>
                )}
              </>
            )}
            <PageHeader.Right.CurrencySpark
              title="Active Projects"
              number={pageData.activeProjectsCount}
              color={green[800]}
              symbol=""
              border
            />
            <PageHeader.Right.CurrencySpark
              title="Total Projects"
              number={pageData.totalProjectsCount}
              color={grey[800]}
              symbol=""
              border
            />
          </PageHeader.Right>
        )}
      </PageHeader>

      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <PaperPanel>
            <PaperPanel.Header isLoading={companyQuery.isFetching}>
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faBuilding} /> Details
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  <PaperPanel.Header.BoxFilesButton href={`/v2/box/files/companies/company/${companyId}/`} />
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.EditButton
                    to={`/v2/companies/${companyId}/update/`}
                    component={MuiNavLink}
                    underline="none"
                  >
                    Edit
                  </PaperPanel.Header.EditButton>
                </PaperPanel.Header.Action>

                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    isFetching={
                      companyQuery.isFetching || highwireContractorQuery.isFetching || overrideQuery.isFetching
                    }
                    onClick={() => {
                      companyQuery.refetch();
                      highwireContractorQuery.refetch();
                      overrideQuery.refetch();
                    }}
                  />
                </PaperPanel.Header.Action>
                {/* {company?.highwire_contractor && permissions.can_override_highwire_approval && (
                  <PaperPanel.Header.Action ml={-2}>
                    <PaperPanel.Header.Menu>
                      {(popupState) => (
                        <div>
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={() => {
                              setHighwireApprovalDialogFormIsOpen(true);
                              popupState.close();
                            }}
                            disabled={!highwireContractorQuery.isFetchedAfterMount}
                          >
                            Highwire Approval
                          </PaperPanel.Header.Menu.MenuItem>
                        </div>
                      )}
                    </PaperPanel.Header.Menu>
                  </PaperPanel.Header.Action>
                )} */}
              </PaperPanel.Header.Actions>
            </PaperPanel.Header>
            <PaperPanel.Body p={1}>
              <LabelValue label="Lookup Code" value={company.lookup_code} />
              {company.nicknames && <LabelValue label="Nicknames" value={company.nicknames} />}
              <LabelValue label="Role" value={company.company_role_description} />
              {isSubcontractor && company.primary_csi_code_display && (
                <LabelValue label="Primary CSI Code" value={company.primary_csi_code_display} />
              )}
              {company.sector_display && <LabelValue label="Sector" value={company.sector_display} />}
              <LabelValue label="Markets" value={company.markets.map((market) => market.description).join(", ")} />
              <LabelValue label="Email Domain" value={company.email_domain || "None"} />
              <Box mb={1} />
              {isSubcontractor && (
                <>
                  <LabelBoolean label="Union" value={company.is_union} />
                  {highwireContractorQuery.isFetching && <FontAwesomeIcon icon={faCircleNotch} spin />}
                  {highwireContractor?.id && !highwireContractorQuery.isFetching && (
                    <>
                      <HighwireInfo
                        highwireContractor={highwireContractor}
                        onOverride={() => setHighwireApprovalDialogFormIsOpen(true)}
                      />
                    </>
                  )}
                  {/* {company.emr && <LabelValue label="EMR" value={company.emr} />} */}
                  {company.company_diversity.length !== 0 && (
                    <LabelValue
                      label="Diversity"
                      value={company.company_diversity.map((diversity) => diversity.description).join(", ")}
                    />
                  )}
                  {/* <LabelBoolean label="Has Company Diversity" value={company.has_company_diversity} /> */}
                  <LabelBoolean
                    label="Master Subcontract"
                    value={company.has_current_executed_master_subcontract_agreements}
                  />
                  {company.is_subcontract_blocklisted && (
                    <LabelBoolean label="Subcontract Blocklisted" value={company.is_subcontract_blocklisted} />
                  )}
                </>
              )}
              {company.signator && <LabelBoolean label="Signator" value={company.signator} />}
              {company.logo_url && (
                <Box mt={1}>
                  <img src={company.logo_url} style={{maxHeight: 50, maxWidth: 400}} />
                </Box>
              )}
            </PaperPanel.Body>
          </PaperPanel>
        </Grid>
        <Grid item xs={12} md={5}>
          <PaperPanel>
            <PaperPanel.Header isLoading={locationsQuery.isFetching}>
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faMapMarker} /> Locations
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  <PaperPanel.Header.CreateButton onClick={() => setShowLocationCreateDialog(true)}>
                    Create
                  </PaperPanel.Header.CreateButton>
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    isFetching={locationsQuery.isFetching}
                    onClick={() => locationsQuery.refetch()}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.Header>
            <PaperPanel.Body maxHeight={250} overflow="auto">
              {locations.map((location, i) => {
                return (
                  <PaperItem key={location.id} p={1}>
                    <PaperItem.Body>
                      <Box flexGrow={1}>
                        {location.is_primary && (
                          <div style={{float: "left"}}>
                            <Tooltip title="Primary Location">
                              <span>
                                <FontAwesomeIcon icon={faCheck} color={colorSuccess} />
                                <Box display="inline" mr={1} />
                              </span>
                            </Tooltip>
                          </div>
                        )}
                        <strong>{location.description}</strong>
                        <Address addressObject={location} />
                        {location.phone && (
                          <Box>
                            <Tooltip title="Phone">
                              <span>
                                <FontAwesomeIcon icon={faPhone} />{" "}
                              </span>
                            </Tooltip>
                            <Link href={`tel:${location.phone}`}>{location.phone}</Link>
                          </Box>
                        )}
                        {location.fax && (
                          <Box>
                            <Tooltip title="Fax">
                              <span>
                                <FontAwesomeIcon icon={faFax} />{" "}
                              </span>
                            </Tooltip>
                            {location.fax}
                          </Box>
                        )}
                        {location.toll_free && (
                          <Box>
                            <Tooltip title="Toll Free">
                              <span>
                                <FontAwesomeIcon icon={faPhone} />{" "}
                              </span>
                            </Tooltip>
                            <Link href={`tel:${location.toll_free}`}>{location.toll_free}</Link>
                          </Box>
                        )}
                      </Box>
                    </PaperItem.Body>
                    <PaperItem.RightHover>
                      <PaperItem.RightHover.IconButton
                        icon={EditIcon}
                        title="Quick Edit"
                        onClick={() => {
                          setActiveLocation(location);
                          setShowLocationUpdateDialog(true);
                        }}
                      />
                      <PaperItem.RightHover.IconButton
                        icon={RoomIcon}
                        title="Google Maps"
                        href={`http://maps.google.com/?q=${location.address1} ${location.address2} ${location.city}, ${location.state} ${location.postal_code}`}
                        target="_blank"
                        component={Link}
                      />
                    </PaperItem.RightHover>
                  </PaperItem>
                );
              })}
              {/* <pre>{JSON.stringify(locations, null, 2)}</pre> */}
            </PaperPanel.Body>
          </PaperPanel>
        </Grid>
      </Grid>
      <Box mt={2} />
      <TabContext value={selectedTab}>
        <PaperPanel>
          <PaperPanel.Body>
            <AppBar
              position="static"
              color="default"
              // elevation={0}
            >
              <Tabs
                value={selectedTab}
                onChange={(event, newValue) => {
                  setSelectedTab(newValue);
                }}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Projects" value="projects" className={novoClasses.smallTab} />
                <Tab label="Contacts" value="contacts" className={novoClasses.smallTab} />
                <Tab
                  label="Commitments"
                  value="commitments"
                  className={novoClasses.smallTab}
                  style={{display: pageData.showTimberlineCommitments ? "inherit" : "none"}}
                />
                <Tab
                  label="Insurance"
                  value="insurance"
                  className={novoClasses.smallTab}
                  style={{
                    display: showInsurance ? "inherit" : "none",
                  }}
                />
                <Tab
                  label="COIs"
                  value="cois"
                  className={novoClasses.smallTab}
                  style={{
                    display: showInsurance ? "inherit" : "none",
                  }}
                />
                <Tab
                  label="Timberline Vendor"
                  value="timberlineVendor"
                  className={novoClasses.smallTab}
                  style={{display: pageData.showTimberlineVendor ? "inherit" : "none"}}
                />
              </Tabs>
            </AppBar>
            <Box mb={2} />

            <TabPanel value="projects">
              <PaperPanel.TabHeader isLoading={projectsQuery.isFetching}>
                {/* <PaperPanel.Header.Title>Projects</PaperPanel.Header.Title> */}
                <PaperPanel.Header.Actions>
                  {permissions.can_view_company_accrual_report && (
                    <PaperPanel.Header.Action>
                      <PaperPanel.Header.Button
                        startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                        href={`/reports2/companies/${companyId}/accrual/`}
                      >
                        Accrual Report
                      </PaperPanel.Header.Button>
                    </PaperPanel.Header.Action>
                  )}
                  <PaperPanel.Header.Action border={permissions.can_view_company_accrual_report}>
                    <PaperPanel.Header.PDFButtons
                      pdfURL={`/reports2/companies/${companyId}/projects/`}
                      buildReportURL={`/reports2/companies/${companyId}/projects/filter/`}
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.RefreshButton
                      isFetching={projectsQuery.isFetching}
                      onClick={() => projectsQuery.refetch()}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>

              <Box mx={-3} mb={-3} mt={-2}>
                <PaperPanel.Toolbar p={1}>
                  <FilterSearch
                    label="Search"
                    value={projectsSearch}
                    name="Search"
                    onChange={(value) => {
                      setProjectsPage(1);
                      setProjectsSearch(value);
                    }}
                  />
                </PaperPanel.Toolbar>
                <ReactTableMui
                  size="small"
                  className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                  // rightAlignColumns={["timestamp"]}
                  columns={projectColumns}
                  initialState={{
                    hiddenColumns: company.company_role_id !== "subcontractor" ? ["csi_codes"] : [],
                  }}
                  data={projects}
                />
                {/* <pre>{JSON.stringify(projects, null, 2)}</pre> */}
                <PaperPanel.Footer display="flex" justifyContent="flex-end">
                  <PaginationWithPageSize
                    count={projectsQuery.data.total_pages}
                    page={projectsPage}
                    pageSize={projectsPageSize}
                    setPage={setProjectsPage}
                    setPageSize={setProjectsPageSize}
                    // pageSizes={[10, 100, 250, 500, 1000]}
                  />
                </PaperPanel.Footer>
              </Box>
              {/* <pre>{JSON.stringify(projects, null, 2)}</pre> */}
            </TabPanel>
            <TabPanel value="contacts">
              <PaperPanel.TabHeader isLoading={contactsQuery.isFetching}>
                {/* <PaperPanel.Header.Title>Projects</PaperPanel.Header.Title> */}
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.CreateButton onClick={() => setShowContactCreateDialog(true)}>
                      Create
                    </PaperPanel.Header.CreateButton>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.RefreshButton
                      isFetching={contactsQuery.isFetching}
                      onClick={() => contactsQuery.refetch()}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              <Box mx={-3} mb={-3} mt={-2}>
                <PaperPanel.Toolbar p={1}>
                  <FilterSearch
                    label="Search"
                    value={projectsSearch}
                    name="Search"
                    onChange={(value) => {
                      setContactsPage(1);
                      setContactsSearch(value);
                    }}
                  />
                </PaperPanel.Toolbar>
                <ReactTableMui
                  size="small"
                  className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
                  // rightAlignColumns={["timestamp"]}
                  onSortBy={(sortBy) => {
                    // TODO: this is not working as changing the order resets table and sorting
                    console.log("onSortBy", sortBy);
                    const ordering = sortBy.map((sort) => {
                      if (sort.id === "full_name") {
                        return sort.desc ? "-first_name,-last_name" : "first_name,last_name";
                      } else {
                        return sort.desc ? `-${sort}` : sort;
                      }
                    });
                    setContactsOrdering(ordering.join(","));
                  }}
                  columns={contactColumns}
                  data={contacts}
                />
                <PaperPanel.Footer display="flex" justifyContent="flex-end">
                  <PaginationWithPageSize
                    count={contactsQuery.data.total_pages}
                    page={contactsPage}
                    pageSize={contactsPageSize}
                    setPage={setContactsPage}
                    setPageSize={setContactsPageSize}
                    // pageSizes={[10, 100, 250, 500, 1000]}
                  />
                </PaperPanel.Footer>
              </Box>
              {/* <pre>{JSON.stringify(contacts, null, 2)}</pre> */}
            </TabPanel>
            {showInsurance && (
              <>
                <TabPanel value="insurance">
                  <PaperPanel.TabHeader isLoading={insuranceQuery.isFetching}>
                    <PaperPanel.Header.Title>
                      <FontAwesomeIcon icon={faUmbrella} /> Insurance
                    </PaperPanel.Header.Title>
                    <PaperPanel.Header.Actions>
                      <PaperPanel.Header.Action>
                        <PaperPanel.Header.CreateButton onClick={() => setShowInsuranceCreateDialog(true)}>
                          Create
                        </PaperPanel.Header.CreateButton>
                      </PaperPanel.Header.Action>
                      <PaperPanel.Header.Action border>
                        <PaperPanel.Header.RefreshButton
                          isFetching={insuranceQuery.isFetching}
                          onClick={() => insuranceQuery.refetch()}
                        />
                      </PaperPanel.Header.Action>
                    </PaperPanel.Header.Actions>
                  </PaperPanel.TabHeader>
                  <Box mx={-3} mb={-3} mt={-2}>
                    <ReactTableMui
                      size="small"
                      className={classnames(
                        novoClasses.stripedTable,
                        novoClasses.smallTable,
                        novoClasses.boldHeaderTable
                      )}
                      // rightAlignColumns={["timestamp"]}
                      columns={insuranceColumns}
                      hover
                      getRowProps={(row) => {
                        return {
                          // hover: true,
                          style: {cursor: "pointer"},
                          onClick: () => {
                            setActiveInsurancePolicy(row.original);
                            setShowInsuranceUpdateDialog(true);
                          },
                        };
                      }}
                      initialState={{
                        sortBy: [
                          {
                            id: "type_display",
                            desc: false,
                          },
                        ],
                      }}
                      data={insurance}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value="cois">
                  <PaperPanel.TabHeader isLoading={coiQuery.isFetching}>
                    {/* <PaperPanel.Header.Title>Projects</PaperPanel.Header.Title> */}
                    <PaperPanel.Header.Actions>
                      <PaperPanel.Header.Action border>
                        <PaperPanel.Header.RefreshButton
                          isFetching={coiQuery.isFetching}
                          onClick={() => coiQuery.refetch()}
                        />
                      </PaperPanel.Header.Action>
                    </PaperPanel.Header.Actions>
                  </PaperPanel.TabHeader>
                  <Box mx={-3} mb={-3} mt={-2}>
                    <PaperPanel.Toolbar p={1}>
                      <Grid container spacing={1}>
                        <Grid item sm={6} xs={12}>
                          <FilterSearch
                            label="Search"
                            value={coiFilterOptions.Search.value}
                            name="search"
                            onChange={(value) => {
                              // setPage(1);
                              setCOIFilterOption("Search", value, value);
                            }}
                          />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                          <FilterSelect
                            // native
                            label="Latest"
                            name="latest"
                            options={[
                              {label: "True", value: "True"},
                              {label: "False", value: "False"},
                            ]}
                            value={coiFilterOptions["Latest"].value}
                            onChange={(value, label) => {
                              // setPage(1);
                              setCOIFilterOption("Latest", value, label);
                            }}
                          />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                          <FilterSelect
                            // native
                            label="Acord Form"
                            name="acord_form"
                            options={insuranceAcordForms}
                            value={coiFilterOptions["Acord Form"].value}
                            onChange={(value, label) => {
                              // setPage(1);
                              setCOIFilterOption("Acord Form", value, label);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid item sm={6} xs={12}>
                          <FilterOptionChips
                            filterOptions={coiFilterOptions}
                            onDelete={(key) => {
                              clearCOIFilterOption(key);
                            }}
                            onDeleteAll={() => {
                              clearAllCOIFilterOptions();
                            }}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Box display="flex" justifyContent="flex-end">
                            <Pagination count={coiQuery.data.total_pages} page={coiPage} setPage={setCOIPage} />
                          </Box>
                        </Grid>
                      </Grid>
                    </PaperPanel.Toolbar>
                    <ReactTableMui
                      size="small"
                      className={classnames(
                        novoClasses.stripedTable,
                        novoClasses.smallTable,
                        novoClasses.boldHeaderTable
                      )}
                      // rightAlignColumns={["timestamp"]}
                      columns={coiColumns}
                      hover
                      getRowProps={(row) => {
                        return {
                          // hover: true,
                          style: {cursor: "pointer"},
                          onClick: () => {
                            setActiveCOI(row.original);
                            setShowUpdateCOIDialog(true);
                          },
                        };
                      }}
                      initialState={{
                        sortBy: [
                          {
                            id: "coi_date",
                            desc: true,
                          },
                        ],
                      }}
                      data={cois}
                    />
                    <PaperPanel.Footer display="flex" justifyContent="flex-end">
                      <PaginationWithPageSize
                        count={coiQuery.data.total_pages}
                        page={coiPage}
                        pageSize={coiPageSize}
                        setPage={setCOIPage}
                        setPageSize={setCOIPageSize}
                        // pageSizes={[10, 100, 250, 500, 1000]}
                      />
                    </PaperPanel.Footer>
                  </Box>
                  {/* <pre>{JSON.stringify(cois, null, 2)}</pre> */}
                </TabPanel>
              </>
            )}
            {pageData.showTimberlineVendor && (
              <TabPanel value="commitments">
                <PaperPanel.TabHeader isLoading={timberlineCommitmentsQuery.isFetching}>
                  <PaperPanel.Header.Title>Commitments</PaperPanel.Header.Title>
                  <PaperPanel.Header.Actions>
                    <PaperPanel.Header.Action>
                      <PaperPanel.Header.Button
                        startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                        disabled={pageDataQuery.isFetching}
                        onClick={(event) => {
                          saveExcel(
                            timberlineCommitments.map((item) => {
                              return {
                                Project: item.project.display,
                                "Project Status": item.project.status,
                                Description: item.description,
                                "Commitment Type": item.commitment_type,
                                Date: {v: item.date, t: "d", z: "yyyy-mm-dd"},
                                "Original Amount": {v: item.amount, t: "n", z: "$#,##0.00"},
                                "Approved CO's": {v: item.approved_commitment_co_amount, t: "n", z: "$#,##0.00"},
                                Amount: {v: item.committed, t: "n", z: "$#,##0.00"},
                                "Amount Invoiced": {v: item.amount_invoiced, t: "n", z: "$#,##0.00"},
                                "Amount Retained": {v: item.amount_being_retained, t: "n", z: "$#,##0.00"},
                                "Amount Paid": {v: item.amount_paid, t: "n", z: "$#,##0.00"},
                              };
                            }),
                            `${company.name} - Commitments`,
                            [
                              {wch: 80},
                              {wch: 20},
                              {wch: 60},
                              {wch: 20},
                              {wch: 20},
                              {wch: 20},
                              {wch: 20},
                              {wch: 20},
                              {wch: 20},
                              {wch: 20},
                              {wch: 20},
                            ]
                          );
                        }}
                      >
                        Export Excel
                      </PaperPanel.Header.Button>
                    </PaperPanel.Header.Action>
                    <PaperPanel.Header.Action border>
                      <PaperPanel.Header.RefreshButton
                        isFetching={timberlineCommitmentsQuery.isFetching}
                        onClick={() => timberlineCommitmentsQuery.refetch()}
                      />
                    </PaperPanel.Header.Action>
                  </PaperPanel.Header.Actions>
                </PaperPanel.TabHeader>
                {/* <pre>{JSON.stringify(timberlineCommitments[0], null, 2)}</pre> */}
                <Box mx={-3} mb={-3} mt={-2}>
                  <ReactTableMui
                    size="small"
                    className={classnames(
                      novoClasses.stripedTable,
                      novoClasses.smallTable,
                      novoClasses.boldHeaderTable
                    )}
                    rightAlignColumns={["commitment", "approved_commitment_co_amount", "committed"]}
                    columns={commitmentColumns}
                    initialState={{
                      sortBy: [
                        {
                          id: "project",
                          desc: false,
                        },
                      ],
                    }}
                    data={timberlineCommitments}
                  />
                  <PaperPanel.Footer display="flex" justifyContent="flex-end">
                    <PaginationWithPageSize
                      count={timberlineCommitmentsQuery.data.total_pages}
                      page={commitmentsPage}
                      pageSize={commitmentsPageSize}
                      setPage={setCommitmentsPage}
                      setPageSize={setCommitmentsPageSize}
                      // pageSizes={[10, 100, 250, 500, 1000]}
                    />
                  </PaperPanel.Footer>
                </Box>
              </TabPanel>
            )}
            {pageData.showTimberlineVendor && (
              <>
                <TabPanel value="timberlineVendor">
                  {pageData.timberlineVendor && company.timberline_vendor ? (
                    <>
                      <PaperPanel.TabHeader>
                        <PaperPanel.Header.Title>{company.timberline_vendor}</PaperPanel.Header.Title>
                        <PaperPanel.Header.Actions>
                          <PaperPanel.Header.Action>
                            <PaperPanel.Header.ColoredButton
                              onClick={() => setUnlinkTimberlineVendorConfirmationIsOpen(true)}
                              color="error"
                              startIcon={<LinkOffIcon />}
                            >
                              Unlink
                            </PaperPanel.Header.ColoredButton>
                          </PaperPanel.Header.Action>
                        </PaperPanel.Header.Actions>
                      </PaperPanel.TabHeader>
                      <Grid container spacing={2}>
                        <Grid item sm={3} xs={12}>
                          <Link href={`/reports2/companies/${companyId}/vendor-work/?year=${currentYear - 1}`}>
                            <PageHeader.Right.CurrencySpark
                              title="Work Last Year"
                              number={pageData.timberlineVendor.workLastYear}
                              textAlign="left"
                            />
                          </Link>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                          <Link href={`/reports2/companies/${companyId}/vendor-work/?year=${currentYear}`}>
                            <PageHeader.Right.CurrencySpark
                              title="Work This Year"
                              number={pageData.timberlineVendor.workThisYear}
                              textAlign="left"
                            />
                          </Link>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                          <Link href={`/reports2/companies/${companyId}/vendor-work/?status=In%20progress`}>
                            <PageHeader.Right.CurrencySpark
                              title="Work In Progress"
                              number={pageData.timberlineVendor.inProgressWork}
                              textAlign="left"
                            />
                          </Link>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                          <Link href={`/reports2/companies/${companyId}/vendor-work/?status=Unstarted`}>
                            <PageHeader.Right.CurrencySpark
                              title="Unstarted Work"
                              number={pageData.timberlineVendor.unstartedWork}
                              textAlign="left"
                            />
                          </Link>
                        </Grid>
                      </Grid>
                      <Box mb={2} />
                      <Divider />
                      <Box mb={2} />
                      <VictoryChart padding={{top: 15, bottom: 20, left: 50, right: 20}} height={100}>
                        <VictoryAxis tickCount={5} style={{tickLabels: {fontSize: 7}}} />
                        <VictoryAxis style={{tickLabels: {fontSize: 7}}} dependentAxis />
                        <VictoryLine
                          height={5000}
                          data={Object.entries(pageData.timberlineVendor.workByYear).map(([key, value]) => {
                            return {
                              x: key,
                              y: value,
                              label: accounting.formatMoney(value, "$", 0),
                            };
                          })}
                          style={{data: {stroke: "#FFBF3F", strokeWidth: 1}, labels: {fontSize: 7, padding: 3}}}
                          containerComponent={<VictoryContainer responsive={true} />}
                        />
                      </VictoryChart>
                      {/* <pre>{JSON.stringify(pageData.timberlineVendor, null, 2)}</pre> */}
                    </>
                  ) : (
                    <>
                      <TimberlineVendorLinkForm
                        onSubmit={(values) => {
                          const updateValues = {...company, timberline_vendor: values.timberline_vendor?.vendor};
                          blockUI.blockUI("Linking Timberline vendor...");
                          updateCompany.mutateAsync(updateValues).then(() => {
                            Promise.all([companyQuery.refetch(), pageDataQuery.refetch()]).then(() => {
                              blockUI.unblockUI();
                            });
                          });
                        }}
                      />
                    </>
                  )}
                </TabPanel>
              </>
            )}
          </PaperPanel.Body>
        </PaperPanel>
      </TabContext>

      {/* NOT a timestamped model: <CreatedByModifiedBy obj={company} mt={1} /> */}
      <CreatedByModifiedBy obj={company} mt={2} />
      <PortalLink href={`/companies/${companyId}/`} mt={1} />

      <CompanyLocationDialogForm
        isOpen={showLocationCreateDialog}
        isNew
        handleClose={() => {
          setActiveLocation({});
          setShowLocationCreateDialog(false);
        }}
        initialValues={{}}
        onSubmit={(values) => {
          setShowLocationCreateDialog(false);
          // refetch because order could change if is_primary is changed
          createLocation.mutateAsync(values).then(() => locationsQuery.refetch());
        }}
      />

      <CompanyLocationDialogForm
        isOpen={showLocationUpdateDialog}
        handleClose={() => {
          setActiveLocation({});
          setShowLocationUpdateDialog(false);
        }}
        initialValues={activeLocation}
        onSubmit={(values) => {
          setShowLocationUpdateDialog(false);
          // refetch because order could change if is_primary is changed
          updateLocation.mutateAsync(values).then(() => locationsQuery.refetch());
        }}
        onDelete={() => {
          setShowLocationUpdateDialog(false);
          setDeleteLocationConfirmationIsOpen(true);
        }}
      />

      <CompanyInsuranceDialogForm
        isOpen={showInsuranceCreateDialog}
        isNew={true}
        handleClose={() => {
          setShowInsuranceCreateDialog(false);
        }}
        initialValues={{}}
        onSubmit={(values) => {
          setShowInsuranceCreateDialog(false);
          // refetch because order could change if is_primary is changed
          createInsurance.mutateAsync(values).then(() => insuranceQuery.refetch());
        }}
      />
      <CompanyInsuranceDialogForm
        isOpen={showInsuranceUpdateDialog}
        handleClose={() => {
          setActiveInsurancePolicy({});
          setShowInsuranceUpdateDialog(false);
        }}
        initialValues={activeInsurancePolicy}
        onSubmit={(values) => {
          setShowInsuranceUpdateDialog(false);
          // refetch because order could change if is_primary is changed
          updateInsurance.mutateAsync(values).then(() => insuranceQuery.refetch());
        }}
        onDelete={() => {
          setShowInsuranceUpdateDialog(false);
          setDeleteInsuranceConfirmationIsOpen(true);
        }}
      />

      <COIUpdateDialogForm
        isOpen={showUpdateCOIDialog}
        handleClose={() => {
          setShowUpdateCOIDialog(false);
        }}
        initialValues={activeCOI}
        onSubmit={(values) => {
          values.expiration_dates_json = values.expiration_dates_json.filter((item) => item !== null);
          blockUI.blockUI("Saving...");
          updateCOI.mutateAsync(values).then((newCloseoutRequest) => {
            blockUI.unblockUI();
            setShowUpdateCOIDialog(false);
            coiQuery.refetch();
          });
        }}
      >
        <PDFObject
          url={`/api/v1/insurance/cois/${activeCOI.uuid}/pdf/`}
          pdfOpenParams={{pagemode: "thumbs", view: "FitH"}}
          containerProps={{style: {width: "100%", height: "60vh"}}}
        />
      </COIUpdateDialogForm>

      <ContactDialogForm
        isOpen={showContactCreateDialog}
        isNew={true}
        handleClose={() => {
          setShowContactCreateDialog(false);
        }}
        initialValues={{company: company}}
        onSubmit={(values) => {
          setShowContactCreateDialog(false);

          const editAfterSubmit = values.editAfterSubmit;
          if (editAfterSubmit) {
            delete values.editAfterSubmit;
            blockUI.blockUI();
          }
          createContact.mutateAsync(values).then((newContact) => {
            if (editAfterSubmit) {
              // navigate(`/contacts/${newContact.id}/`);
              window.location.href = `/contacts/${newContact.id}/`;
              blockUI.unblockUI();
            } else {
              contactsQuery.refetch();
            }
          });
          // refetch because order could change if is_primary is changed
          // updateLocation.mutateAsync(values).then(() => locationsQuery.refetch());
        }}
      />

      <ConfirmationDialog
        isOpen={deleteLocationConfirmationIsOpen}
        onApprove={() => {
          setDeleteLocationConfirmationIsOpen(false);
          deleteLocation.mutateAsync(activeLocation.id);
        }}
        onDeny={() => setDeleteLocationConfirmationIsOpen(false)}
      >
        You want to delete location {activeLocation?.description || activeLocation?.address1}.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={deleteInsuranceConfirmationIsOpen}
        onApprove={() => {
          setDeleteInsuranceConfirmationIsOpen(false);
          deleteInsurance.mutateAsync(activeInsurancePolicy.id);
        }}
        onDeny={() => setDeleteInsuranceConfirmationIsOpen(false)}
      >
        You want to delete insurance policy {activeInsurancePolicy?.type_display} -{" "}
        {activeInsurancePolicy?.policy_number}.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={markContactAsPrimaryConfirmationIsOpen}
        onApprove={() => {
          setMarkContactAsPrimaryConfirmationIsOpen(false);
          blockUI.blockUI("Marking as primary...");
          updateContact.mutateAsync({...activeContact, is_primary: true}).then(() => {
            contactsQuery.refetch().then(() => blockUI.unblockUI());
          });
        }}
        onDeny={() => setMarkContactAsPrimaryConfirmationIsOpen(false)}
      >
        You want to mark {activeContact?.full_name} as the primary contact for {company.name}.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={unlinkTimberlineVendorConfirmationIsOpen}
        onApprove={() => {
          setUnlinkTimberlineVendorConfirmationIsOpen(false);
          const updateValues = {...company, timberline_vendor: null};
          updateCompany.mutateAsync(updateValues).then(() => {
            companyQuery.refetch();
            pageDataQuery.refetch();
          });
        }}
        onDeny={() => setUnlinkTimberlineVendorConfirmationIsOpen(false)}
      >
        You want to unlink this company from Timberline. Don't do this unless you know what you are doing.
      </ConfirmationDialog>

      <HighwireApprovalOverrideDialogForm
        contractor={highwireContractor}
        isOpen={highwireApprovalDialogFormIsOpen}
        handleClose={() => setHighwireApprovalDialogFormIsOpen(false)}
        initialValues={override}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          axiosAPI.post(`/highwire/contractors/${highwireContractor?.id}/override/`, values).then(() =>
            Promise.all([highwireContractorQuery.refetch(), overrideQuery.refetch()]).then(() => {
              blockUI.unblockUI();
              setHighwireApprovalDialogFormIsOpen(false);
            })
          );
        }}
      />
      {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(highwireContractor, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(company, null, 2)}</pre> */}
      {/* <h1>Company</h1>
      <pre>{JSON.stringify(company, null, 2)}</pre>
      <h1>Page Data</h1>
      <pre>{JSON.stringify(pageData, null, 2)}</pre>
      <h1>Perms</h1>
      <pre>{JSON.stringify(permissions, null, 2)}</pre> */}
    </>
  );
};

export default CompanyDetail;

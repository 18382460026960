import {faCircleCheck, faCircleX} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Tooltip} from "@material-ui/core";
import {colorError, colorSuccess} from "../../theme/colors";

export const HighwireStatusIcon = (props) => {
  const {highwireContractor} = props;
  const getTooltip = () => {
    let tooltip = highwireContractor.is_approved ? "Approved" : "Not Approved";
    if (highwireContractor?.is_approved_reason) {
      tooltip += ` (${highwireContractor?.is_approved_reason})`;
    }
    return tooltip;
  };

  return (
    <Tooltip title={getTooltip()}>
      <span>
        {highwireContractor.is_approved ? (
          <FontAwesomeIcon icon={faCircleCheck} style={{color: colorSuccess}} />
        ) : (
          <FontAwesomeIcon icon={faCircleX} style={{color: colorError}} />
        )}
      </span>
    </Tooltip>
  );
};

import {Box, Breadcrumbs, Link, Typography} from "@material-ui/core";
import {Helmet} from "react-helmet";
import {PDFObject} from "react-pdfobject";
import {useParams} from "react-router";
import {ProjectBreadcrumbs} from "../../components/Breadcrumbs";
import BlockUI from "../../components/GlobalLoaders";
import {MuiNavLink} from "../../components/Links";
import PaperPanel from "../../components/PaperPanel";
import {SuperuserInfo} from "../../components/SuperuserInfo";
import useBlockUI from "../../hooks/useBlockUI";
import {useSentinelDetailAPI} from "../../hooks/useSentinelAPI";
import {COIInsuranceRequirementsIcon} from "./COIComponents";
import {COIUpdateForm} from "./COIForms";

const COIDetail = (props) => {
  const {coiUUID} = useParams();
  const blockUI = useBlockUI();

  const {
    query: coiQuery,
    update: updateCOI,
    delete: deleteCOI,
    rpc: coiRPC,
  } = useSentinelDetailAPI(`insurance/cois/${coiUUID}/`, {
    initialData: {},
    // https://react-query.tanstack.com/guides/paginated-queries
    // keepPreviousData: true,
  });
  const coi = coiQuery.data;

  if (!coiQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching COI..." />;
  }

  return (
    <>
      <Helmet title="COI Detail" />
      {/* <pre>{JSON.stringify(coi, null, 2)}</pre> */}
      {coi.project ? (
        <ProjectBreadcrumbs project={coi.project}>
          <Link color="inherit" href={`/v2/projects/${coi.project.id}/insurance/#tab=cois`}>
            COIs
          </Link>
          <Typography color="textPrimary">{coi.display}</Typography>
        </ProjectBreadcrumbs>
      ) : (
        <Breadcrumbs>
          <MuiNavLink color="inherit" to="/v2/dashboard/">
            Dashboard
          </MuiNavLink>
          <MuiNavLink color="inherit" to={`/v2/insurance/cois/`}>
            COI Dashboard
          </MuiNavLink>
          <Typography color="textPrimary">{coi.display}</Typography>
        </Breadcrumbs>
      )}

      {/* <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>COI Dashboard</PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader> */}
      <Box m={2} />

      <PaperPanel>
        <PaperPanel.Header isLoading={coiQuery.isFetching}>
          <PaperPanel.Header.Title>
            <COIInsuranceRequirementsIcon coi={coi} /> COI: {coi.display}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton isFetching={coiQuery.isFetching} onClick={() => coiQuery.refetch()} />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body p={1}>
          <COIUpdateForm
            initialValues={coi}
            onSubmit={(values) => {
              values.expiration_dates_json = values.expiration_dates_json.filter((item) => item !== null);
              blockUI.blockUI("Saving...");
              updateCOI.mutateAsync(values).then((newCloseoutRequest) => {
                blockUI.unblockUI();
                coiQuery.refetch();
              });
            }}
            onDelete={() => {
              blockUI.blockUI("Deleting...");
              deleteCOI.mutateAsync(coi.id).then(() => {
                blockUI.unblockUI();
                window.location.href = `/v2/insurance/cois/`;
              });
            }}
            onProcessWithAI={() => {
              blockUI.blockUI("Processing with AI...");
              coiRPC.mutateAsync({action: "process-with-ai"}).then(() => {
                coiQuery.refetch();
                blockUI.unblockUI();
              });
            }}
            onProcessLimitsWithAI={() => {
              blockUI.blockUI("Processing limits with AI...");
              coiRPC.mutateAsync({action: "process-limits-with-ai"}).then(() => {
                coiQuery.refetch();
                blockUI.unblockUI();
              });
            }}
          />
          <Box mt={2} />
          <PDFObject
            url={`/api/v1/insurance/cois/${coi.uuid}/pdf/`}
            pdfOpenParams={{pagemode: "thumbs", view: "FitH"}}
            containerProps={{style: {width: "100%", height: "60vh"}}}
          />
          <SuperuserInfo
            objects={[
              {
                contentType: "insurance",
                model: "certificateofinsurance",
                id: coi.id,
                title: "COI",
              },
            ]}
          />
          {/* <PDFObject
            url={`/api/v1/insurance/cois/${coi.uuid}/pdf/`}
            pdfOpenParams={{pagemode: "thumbs", view: "FitH"}}
            containerProps={{style: {width: "100%", height: "60vh"}}}
          /> */}
          {/* <pre>{JSON.stringify(coi, null, 2)}</pre> */}
        </PaperPanel.Body>
      </PaperPanel>

      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};

export default COIDetail;

import {faChartLine, faCheck, faFileExcel, faMicrochipAi, faUsers} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Link,
  Switch,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {green, red} from "@material-ui/core/colors";
import accounting from "accounting";
import classnames from "classnames";
import moment from "moment";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {Helmet} from "react-helmet";
import {useNavigate, useParams} from "react-router";
import AutoSaveDebounce from "../../../../js/components/ReactFinalForm/AutoSaveDebounce";
import {Currency} from "../../../components/Accounting";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import InnerFooter from "../../../components/InnerFooter";
import {MuiNavLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import PaperPanel from "../../../components/PaperPanel";
import {PrevNext} from "../../../components/PrevNext";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {CurrencyFieldMui, DatePickerMui, TextFieldMui} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import useBlockUI from "../../../hooks/useBlockUI";
import {useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {saveExcel} from "../../../utils/excel";
import {ProjectForecastCostCodeChart, ProjectForecastLMOEChart} from "./ProjectForecastCharts";

const useStyles = makeStyles({
  table: {
    "& tr.exclude td": {
      "text-decoration": "line-through",
    },
  },
});

const ProjectForecastDetail = (props) => {
  const {project, userEmployee, ...rest} = props;
  const {forecastId} = useParams();

  const novoClasses = makeNovoClasses();
  const classes = useStyles();

  const [copyCurrentCostToExpectedCostConfirmationIsOpen, setCopyCurrentCostToExpectedCostConfirmationIsOpen] =
    React.useState(false);
  const [copyCurrentBudgetToExpectedCostConfirmationIsOpen, setCopyCurrentBudgetToExpectedCostConfirmationIsOpen] =
    React.useState(false);
  const [predictLMEOCostConfirmationIsOpen, setPredictLMEOCostConfirmationIsOpen] = React.useState(false);
  const [markCompleteConfirmationIsOpen, setMarkCompleteConfirmationIsOpen] = React.useState(false);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);
  const [copyForecastConfirmationIsOpen, setCopyForecastConfirmationIsOpen] = React.useState(false);
  const [copyToExpectedConfirmationIsOpen, setCopyToExpectedConfirmationIsOpen] = React.useState(false);
  const [copyToExpectedCostAmount, setCopyToExpectedCostAmount] = React.useState(0);
  const [breakOutCosts, setBreakOutCosts] = React.useState(false);
  const [activeForecastItem, setActiveForecastItem] = React.useState({} as any);
  const [forecastAmount, setForecastAmount] = React.useState({} as any);
  const [showForecastItemDialog, setShowForecastItemDialog] = React.useState(false);

  const blockUI = useBlockUI();
  const navigate = useNavigate();

  const {
    query: forecastQuery,
    update: updateForecast,
    delete: deleteForecast,
    rpc: forecastRPC,
  } = useSentinelDetailAPI(`projects/${project?.id}/forecasts/${forecastId}/`, {
    initialData: {},
  });

  const {query: forecastItemsQuery, update: updateForecastItem} = useSentinelListAPI(
    `projects/${project.id}/forecasts/${forecastId}/items/`,
    {
      initialData: {
        results: [],
      },
    }
  );

  const forecast = forecastQuery.data;
  const forecastItems = forecastItemsQuery.data.results;
  const isReadOnly = forecast?.is_closed;

  let rightAlignColumns = [
    "sentinel_current_budget",
    "sentinel_current_cost",
    "tl_lmoe",
    "total_cost",
    "expected_profit",
  ];
  if (isReadOnly) {
    rightAlignColumns.push("expected_total_cost");
  }

  // Footers will not work with memoized columns
  const forecastColumns = React.useMemo(
    () => [
      {
        Header: "Budget Code",
        accessor: "budget_code",
        Cell: ({value, row}) => {
          return (
            <Link
              onClick={() => {
                setActiveForecastItem(row.original);
                setShowForecastItemDialog(true);
              }}
              style={{cursor: "pointer"}}
              underline="always"
            >
              <>
                {row.original.exclude ? (
                  <Tooltip title="Excluded">
                    <span>{value}</span>
                  </Tooltip>
                ) : (
                  value
                )}
              </>
            </Link>
          );
        },
      },
      {
        Header: "Allocation",
        accessor: "allocation",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Current Budget",
        accessor: "sentinel_current_budget",
        Cell: ({value, row}) => (
          <>
            {isReadOnly ? (
              <Currency number={value} precision={2} />
            ) : (
              <Link
                underline="always"
                style={{cursor: "pointer"}}
                onClick={() => {
                  setActiveForecastItem(row.original);
                  setCopyToExpectedCostAmount(value);
                  setCopyToExpectedConfirmationIsOpen(true);
                }}
              >
                <Currency number={value} precision={2} />
              </Link>
            )}
          </>
        ),
        // Footer: () => <Currency number={forecast.sentinel_current_budget} />,
      },
      {
        Header: "Committed Costs",
        accessor: "sentinel_current_cost",
        Cell: ({value, row}) => <Currency number={value} precision={2} />,
        // Footer: () => <Currency number={forecast.total_cost} />,
      },
      {
        Header: "LMOE",
        accessor: "tl_lmoe",
        Cell: ({value, row}) => <Currency number={value} precision={2} />,
        // Footer: () => <Currency number={forecast.total_cost} />,
      },
      {
        Header: "JTD Total Cost",
        accessor: "total_cost",
        Cell: ({value, row}) => (
          <>
            {isReadOnly ? (
              <Currency number={value} precision={2} />
            ) : (
              <Link
                underline="always"
                style={{cursor: "pointer"}}
                onClick={() => {
                  setActiveForecastItem(row.original);
                  setCopyToExpectedCostAmount(value);
                  setCopyToExpectedConfirmationIsOpen(true);
                }}
              >
                <Currency number={value} precision={2} />
              </Link>
            )}
          </>
        ),
        // Footer: () => <Currency number={forecast.total_cost} />,
      },
      {
        Header: "Expected Profit",
        accessor: "expected_profit",
        Cell: ({value, row}) => <Currency number={value} precision={2} />,
        // Footer: () => <Currency number={forecast.expected_total_profit} />,
      },
      {
        Header: "Expected Total Cost",
        accessor: "expected_total_cost",
        Cell: ({value, row}) => (
          <Box pt={0.5} pb={0.5}>
            <FinalForm
              onSubmit={(values) => {
                // console.log("update", values);
                updateForecastItem.mutateAsync(values).then(() => {
                  forecastQuery.refetch();
                });
              }}
              initialValues={{
                id: row.original.id,
                expected_total_cost: row.original.expected_total_cost,
              }}
            >
              {(props) => {
                const {form, pristine} = props;
                return (
                  <>
                    {isReadOnly ? (
                      <Currency number={value} precision={2} />
                    ) : (
                      <CurrencyFieldMui
                        name="expected_total_cost"
                        label="Expected Total Cost"
                        required
                        disabled={row.original.exclude}
                        onFocus={(event) => {
                          event.target.select();
                        }}
                        onBlur={() => {
                          if (!pristine) {
                            // HACK: to allow tab to get to the next field before submitting
                            setTimeout(() => {
                              form.submit();
                            }, 100);
                          }
                        }}
                      />
                    )}
                  </>
                );
              }}
            </FinalForm>
          </Box>
        ),
        // Footer: () => <Currency number={forecast.expected_total_cost} />,
      },
      {
        Header: "Comments",
        accessor: "comments",
        Cell: ({value, row}) => (
          <Box pt={0.5} pb={0.5}>
            <FinalForm
              onSubmit={(values) => {
                // console.log("update", values);
                updateForecastItem.mutateAsync(values).then(() => {
                  forecastQuery.refetch();
                });
              }}
              initialValues={{
                id: row.original.id,
                comments: row.original.comments,
              }}
            >
              {(props) => {
                const {form, pristine} = props;
                return (
                  <>
                    {isReadOnly ? (
                      <>{value}</>
                    ) : (
                      <TextFieldMui
                        name="comments"
                        label="Comments"
                        disabled={row.original.exclude}
                        onFocus={(event) => {
                          event.target.select();
                        }}
                        onBlur={() => {
                          if (!pristine) {
                            // HACK: to allow tab to get to the next field before submitting
                            setTimeout(() => {
                              form.submit();
                            }, 100);
                          }
                        }}
                      />
                    )}
                  </>
                );
              }}
            </FinalForm>
          </Box>
        ),
        // Footer: () => <Currency number={forecast.expected_total_cost} />,
      },
    ],
    [breakOutCosts, isReadOnly]
  );

  if (!forecastQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching forecast..." />;
  }
  return (
    <>
      <Helmet title={forecast.display} />
      <ProjectBreadcrumbs project={project}>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/forecasts/`}>
          Forecasts
        </MuiNavLink>
        <Typography color="textPrimary">{forecast.display}</Typography>
      </ProjectBreadcrumbs>
      {/*
      <PaperPanel.Alert severity="info">
        This feature is currently in <strong>beta</strong> prerelease testing. Please refrain from using it unless
        specifically requested to do so.
      </PaperPanel.Alert>
      <Box mb={2} />
      */}

      <PageHeader>
        <PageHeader.Left>
          <PageHeader.Title></PageHeader.Title>
        </PageHeader.Left>
        {forecast && (
          <Box>
            <Box>
              <PageHeader.Right>
                <PageHeader.Right.CurrencySpark
                  title="Current Budget"
                  number={forecast.sentinel_current_budget}
                  // color={green[800]}
                />
                <PageHeader.Right.CurrencySpark
                  title="JTD Cost"
                  number={forecast.total_cost}
                  // color={red[800]}
                  border
                />
              </PageHeader.Right>
            </Box>
            <Box mt={1}>
              <PageHeader.Right>
                <PageHeader.Right.CurrencySpark
                  title="Expected Value"
                  number={forecast.expected_total_value}
                  // color={green[800]}
                />
                <PageHeader.Right.CurrencySpark
                  title="Expected Cost"
                  number={forecast.expected_total_cost}
                  // color={red[800]}
                  border
                />
                <PageHeader.Right.RightSpark
                  title="Overhead %"
                  value={`${accounting.formatNumber(forecast.overhead_percent * 100, 1)}%`}
                  border
                />
                <PageHeader.Right.CurrencySpark
                  title="Overhead"
                  number={forecast.overhead}
                  // color={red[800]}
                  border
                />
                <PageHeader.Right.CurrencySpark
                  title="Expected Profit"
                  number={forecast.expected_total_profit}
                  color={forecast.expected_total_profit < 0 ? red[800] : green[800]}
                  border
                />
                <PageHeader.Right.RightSpark
                  title="Expected Profit %"
                  value={`${accounting.formatNumber(forecast.expected_total_profit_percent * 100, 1)}%`}
                  color={forecast.expected_total_profit_percent < 0 ? red[800] : green[800]}
                  border
                />
              </PageHeader.Right>
            </Box>
          </Box>
        )}
      </PageHeader>

      <PaperPanel>
        <PaperPanel.Header isLoading={forecastQuery.isFetching || forecastItems.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faChartLine} /> LMOE Forecast {project.display}{" "}
          </PaperPanel.Header.Title>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ProjectForecastLMOEChart projectId={project.id} />
        </PaperPanel.Body>
      </PaperPanel>

      <Box mt={2} />

      <PaperPanel>
        <PaperPanel.Header isLoading={forecastQuery.isFetching || forecastItems.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faChartLine} /> Forecast {forecast.display}{" "}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <FormControlLabel
                control={<Switch checked={breakOutCosts} onChange={() => setBreakOutCosts((prev) => !prev)} />}
                label="Break Out Costs"
              />
            </PaperPanel.Header.Action>
            {userEmployee && forecast.project_manager_meeting && (
              <PaperPanel.Header.Action border>
                <PaperPanel.Header.Button
                  startIcon={<FontAwesomeIcon icon={faUsers} />}
                  href={`/v2/employees/${userEmployee.id}/pm-meetings/${forecast.project_manager_meeting}/`}
                >
                  PM Meeting
                </PaperPanel.Header.Button>
              </PaperPanel.Header.Action>
            )}
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={forecastItemsQuery.isFetching}
                onClick={(event) => {
                  saveExcel(
                    forecastItems.map((item) => {
                      return {
                        "Budget Code": item.budget_code,
                        Allocation: item.allocation,
                        Description: item.description,
                        "Current Budget": item.sentinel_current_budget,
                        "JTD Total Cost": item.total_cost,
                        "Expected Profit": item.expected_profit,
                        "Expected Total Cost": item.expected_total_cost,
                        Comments: item.comments,
                      };
                    }),
                    `${project.display} - Forecast ${forecast.display}`,
                    [{wch: 10}, {wch: 15}, {wch: 40}, {wch: 15}, {wch: 15}, {wch: 15}, {wch: 20}, {wch: 25}]
                  );
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            {!isReadOnly && (
              <>
                {/* <PaperPanel.Header.Action border>
                  <PaperPanel.Header.Button
                    startIcon={<FileCopyIcon />}
                    onClick={() => setCopyCurrentCostToExpectedCostConfirmationIsOpen(true)}
                  >
                    Copy Current Cost to Expected Cost
                  </PaperPanel.Header.Button>
                </PaperPanel.Header.Action> */}
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.DropdownMenu title="Copy To Expected Cost">
                    {(setOpen) => (
                      <>
                        <PaperPanel.Header.Menu.MenuItem
                          onClick={() => {
                            setOpen(false);
                            setCopyCurrentCostToExpectedCostConfirmationIsOpen(true);
                          }}
                        >
                          Copy Current Cost to Expected Cost
                        </PaperPanel.Header.Menu.MenuItem>
                        <PaperPanel.Header.Menu.MenuItem
                          onClick={(event) => {
                            setOpen(false);
                            setCopyCurrentBudgetToExpectedCostConfirmationIsOpen(true);
                          }}
                        >
                          Copy Current Budget to Expected Cost
                        </PaperPanel.Header.Menu.MenuItem>
                      </>
                    )}
                  </PaperPanel.Header.DropdownMenu>
                </PaperPanel.Header.Action>
              </>
            )}
            {!isReadOnly && (
              <PaperPanel.Header.Action border>
                <Tooltip title={forecast.expected_lmeo_finish_date ? "" : "Must Enter Expected LMEO Finish Date"}>
                  <span>
                    <PaperPanel.Header.Button
                      startIcon={<FontAwesomeIcon icon={faMicrochipAi} />}
                      onClick={() => setPredictLMEOCostConfirmationIsOpen(true)}
                      disabled={!forecast.expected_lmeo_finish_date}
                    >
                      Predict LMEO Costs
                    </PaperPanel.Header.Button>
                  </span>
                </Tooltip>
              </PaperPanel.Header.Action>
            )}
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                isFetching={forecastQuery.isFetching}
                onClick={() => {
                  forecastQuery.refetch();
                  forecastItemsQuery.refetch();
                }}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        {!isReadOnly && (
          <PaperPanel.Toolbar p={1}>
            <FinalForm
              onSubmit={(values) => {
                updateForecast.mutateAsync(values).then(() => {
                  forecastQuery.refetch();
                });
              }}
              initialValues={{
                id: forecast.id,
                expected_total_value: forecast.expected_total_value,
                expected_lmeo_finish_date: forecast.expected_lmeo_finish_date,
              }}
            >
              {(props) => {
                const {form, pristine} = props;
                return (
                  <>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <CurrencyFieldMui
                          name="expected_total_value"
                          label="Expected Total Value"
                          required
                          disabled={isReadOnly}
                          // onBlur={() => {
                          //   if (!pristine) {
                          //     form.submit();
                          //   }
                          // }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <DatePickerMui
                          name="expected_lmeo_finish_date"
                          label="Expected LMEO Finish Date"
                          disabled={isReadOnly}
                        />
                      </Grid>
                    </Grid>
                    <AutoSaveDebounce
                      save={(values) => {
                        if (isReadOnly) {
                          return;
                        }
                        if (values.expected_lmeo_finish_date && !moment(values.expected_lmeo_finish_date).isValid()) {
                          return;
                        }
                        console.log("save", values);
                        form.submit();
                      }}
                    />
                  </>
                );
              }}
            </FinalForm>
          </PaperPanel.Toolbar>
        )}
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            containerStyle={{maxHeight: "70vh"}}
            className={classnames(
              novoClasses.stripedTable,
              novoClasses.mediumTable,
              novoClasses.boldHeaderTable,
              classes.table
            )}
            // showFooter={true}
            stickyHeader
            rightAlignColumns={rightAlignColumns}
            getRowProps={(row) => {
              return {
                className: row.original.exclude ? "exclude" : "",
                "data-id": row.original.id,
              };
            }}
            columns={forecastColumns}
            initialState={{
              sortBy: [
                {
                  id: "budget_code",
                  desc: false,
                },
              ],
              hiddenColumns: breakOutCosts ? [] : ["sentinel_current_cost", "tl_lmoe"],
            }}
            data={forecastItems}
          />
          {/* {forecastItems.map((forecastItem) => {
            return (
              <ProjectForecastItemPaperItem
                key={forecastItem.id}
                forecastItem={forecastItem}
                onUpdate={(values) => {
                  updateForecastItem.mutateAsync(values).then(() => {
                    forecastQuery.refetch();
                  });
                }}
              />
            );
          })} */}
        </PaperPanel.Body>
        {/* <pre>{JSON.stringify(forecastQuery, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(forecastItemsQuery, null, 2)}</pre> */}
      </PaperPanel>

      {!isReadOnly && (
        <InnerFooter>
          <Box display="flex">
            <FormActions.Button
              color="primary"
              label="Mark Complete"
              startIcon={<FontAwesomeIcon icon={faCheck} />}
              onClick={() => {
                setMarkCompleteConfirmationIsOpen(true);
              }}
            />

            <Box ml={1} />
            <FormActions.DeleteButton
              onClick={() => {
                setDeleteConfirmationIsOpen(true);
              }}
            />
          </Box>
        </InnerFooter>
      )}

      <Dialog open={showForecastItemDialog} onClose={() => setShowForecastItemDialog(false)} maxWidth="xl" fullWidth>
        <DialogTitle id="form-dialog-title">
          <Grid container justifyContent="space-between">
            <Grid item>
              {activeForecastItem.budget_code} - {activeForecastItem.description}
            </Grid>
            <Grid item>
              <PrevNext
                items={forecastItems}
                setActiveItem={setActiveForecastItem}
                activeItem={activeForecastItem}
                displayProp="budget_code"
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box display="flex">
            <PageHeader.Right.CurrencySpark
              title="Current Budget"
              number={activeForecastItem.sentinel_current_budget}
              textAlign="left"
              // color={green[800]}
            />
            <PageHeader.Right.CurrencySpark
              title="JTD Cost"
              number={activeForecastItem.total_cost}
              // color={red[800]}
              border
            />
            <PageHeader.Right.CurrencySpark
              title="Expected Total Cost"
              number={activeForecastItem.expected_total_cost}
              // color={red[800]}
              border
            />
            <PageHeader.Right.CurrencySpark
              title="Expected Profit"
              number={activeForecastItem.expected_profit}
              color={activeForecastItem.expected_profit < 0 ? red[800] : green[800]}
              // color={red[800]}
              border
            />
          </Box>
          <Box mt={3} />
          <ProjectForecastCostCodeChart
            projectId={project.id}
            costCode={activeForecastItem.budget_code}
            isReadOnly={isReadOnly}
            onClickForecast={(data) => {
              setCopyForecastConfirmationIsOpen(true);
              setForecastAmount(data.datum.mean.toFixed(0));
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowForecastItemDialog(false)} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        isOpen={copyForecastConfirmationIsOpen}
        onApprove={() => {
          setCopyForecastConfirmationIsOpen(false);
          blockUI.blockUI("Copying Forecast Amount...");
          const values = {
            id: activeForecastItem.id,
            expected_total_cost: forecastAmount,
          };
          // setActiveForecastItem({...activeForecastItem, ...values});
          updateForecastItem.mutateAsync(values).then((item) => {
            forecastQuery.refetch();
            setActiveForecastItem(item);
            setCopyForecastConfirmationIsOpen(false);
            blockUI.unblockUI();
          });
        }}
        onDeny={() => {
          setCopyForecastConfirmationIsOpen(false);
        }}
      >
        Are you sure you want to set {accounting.formatMoney(forecastAmount, "$", 0)} as the expected total cost for{" "}
        {activeForecastItem.budget_code}? This cannot be undone.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={copyCurrentCostToExpectedCostConfirmationIsOpen}
        onApprove={() => {
          setCopyCurrentCostToExpectedCostConfirmationIsOpen(false);
          blockUI.blockUI("Copying the current cost to expected cost...");
          forecastRPC.mutateAsync({action: "copy-cost-to-expected-cost"}).then(() => {
            Promise.all([forecastQuery.refetch(), forecastItemsQuery.refetch()]).then(() => {
              blockUI.unblockUI();
            });
          });
        }}
        onDeny={() => {
          setCopyCurrentCostToExpectedCostConfirmationIsOpen(false);
        }}
      >
        You want to copy the current cost to expected cost? This will overwrite any existing expected cost values. This
        cannot be undone.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={copyCurrentBudgetToExpectedCostConfirmationIsOpen}
        onApprove={() => {
          setCopyCurrentBudgetToExpectedCostConfirmationIsOpen(false);
          blockUI.blockUI("Copying the current budget to expected cost...");
          forecastRPC.mutateAsync({action: "copy-budget-to-expected-cost"}).then(() => {
            Promise.all([forecastQuery.refetch(), forecastItemsQuery.refetch()]).then(() => {
              blockUI.unblockUI();
            });
          });
        }}
        onDeny={() => {
          setCopyCurrentBudgetToExpectedCostConfirmationIsOpen(false);
        }}
      >
        You want to copy the current budget to expected cost? This will overwrite any existing expected cost values.
        This cannot be undone.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={copyToExpectedConfirmationIsOpen}
        onApprove={() => {
          setCopyToExpectedConfirmationIsOpen(false);
          // blockUI.blockUI("Copying to expected cost...");
          updateForecastItem
            .mutateAsync({id: activeForecastItem.id, expected_total_cost: copyToExpectedCostAmount})
            .then(() => {
              forecastQuery.refetch();
            });
        }}
        onDeny={() => {
          setCopyToExpectedConfirmationIsOpen(false);
        }}
      >
        You want to copy <Currency number={copyToExpectedCostAmount} /> This will overwrite the current expected cost
        value. This cannot be undone.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={predictLMEOCostConfirmationIsOpen}
        onApprove={() => {
          setPredictLMEOCostConfirmationIsOpen(false);
          blockUI.blockUI("Predicting LMEO Costs...");
          forecastRPC.mutateAsync({action: "predict-lmeo-costs"}).then(() => {
            Promise.all([forecastQuery.refetch(), forecastItemsQuery.refetch()]).then(() => {
              blockUI.unblockUI();
            });
          });
        }}
        onDeny={() => {
          setPredictLMEOCostConfirmationIsOpen(false);
        }}
      >
        Are you sure you want to try and predict the LMEO costs on {forecast.expected_lmeo_finish_date}? This will
        overwrite any existing expected cost values. This cannot be undone.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={markCompleteConfirmationIsOpen}
        onApprove={() => {
          setMarkCompleteConfirmationIsOpen(false);
          blockUI.blockUI("Marking complete...");
          forecastRPC.mutateAsync({action: "mark-complete"}).then(() => {
            Promise.all([forecastQuery.refetch(), forecastItemsQuery.refetch()]).then(() => {
              blockUI.unblockUI();
            });
          });
        }}
        onDeny={() => {
          setMarkCompleteConfirmationIsOpen(false);
        }}
      >
        You want to mark this forecast complete. You will not be able to edit this forecast after it is marked complete.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        onApprove={() => {
          setDeleteConfirmationIsOpen(false);
          blockUI.blockUI("Deleting...");
          deleteForecast.mutateAsync().then(() => {
            navigate(`/v2/projects/${project.id}/forecasts/`);
            blockUI.unblockUI();
          });
        }}
        onDeny={() => {
          setDeleteConfirmationIsOpen(false);
        }}
      >
        You want to delete this for forecast? This cannot be undone.
      </ConfirmationDialog>
    </>
  );
};

export default ProjectForecastDetail;
